/* eslint camelcase: off */
import { useNuxtApp } from 'nuxt/app';
import { defineStore } from 'pinia';
import { useUserStore } from '~/stores/User';

/**
 * @todo This Pinia store will eventually be used for Alert Areas notifications. Currently Alert
 * Areas are managed in the User Pinia store, but should be moved here for consistency.
 */
export const useUserNotificationsStore = defineStore('userNotifications', {
  state: () => ({
    newsletters: null,
  }),

  actions: {
    async fetchNewsletters() {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { cache_key } = userStore.user || {};

      const response = await $api.get('/user/notifications/newsletters', { cache_key });
      this.saveNewsletters(response);
    },

    async optInToReceiveNewsletters() {
      const { $api } = useNuxtApp();
      const response = await $api.post('/user/notifications/newsletters/external-opt-in');
      this.saveNewsletters(response);
    },

    async subscribeToNewsletterSegment(segmentId) {
      const { $api } = useNuxtApp();
      const response = await $api.post(`/user/notifications/newsletters/subscribe/${segmentId}`);
      this.saveNewsletters(response);
    },

    async unsubscribeToNewsletter(newsletterId) {
      const { $api } = useNuxtApp();
      const path = `/user/notifications/newsletters/unsubscribe${newsletterId ? `/${newsletterId}` : ''}`;
      const response = await $api.post(path);
      this.saveNewsletters(response);
    },

    async fetchDailyReads() {
      const { $api } = useNuxtApp();
      const userStore = useUserStore();
      const { cache_key } = userStore.user || {};

      const { daily_reads } = await $api.get('/user/notifications/daily-reads', { cache_key });
      this.saveDailyReads(daily_reads);
    },

    async subscribeToDailyRead(dailyReadId) {
      const { $api } = useNuxtApp();
      const { daily_reads } = await $api.put(`/user/notifications/daily-reads/${dailyReadId}`);
      this.saveDailyReads(daily_reads);
    },

    async unsubscribeToDailyRead(dailyReadId) {
      const { $api } = useNuxtApp();
      const { daily_reads } = await $api.delete(`/user/notifications/daily-reads/${dailyReadId}`);
      this.saveDailyReads(daily_reads);
    },

    saveNewsletters(response) {
      this.newsletters = response;
    },

    saveDailyReads(response) {
      this.dailyReads = response;
    },
  },

  getters: {
    isSubscribedToDailyRead: (state) => ({ dailyReadId }) => {
      const dailyReadIds = state?.dailyReads?.flatMap((item) => item.id) || [];

      if (dailyReadIds) {
        return dailyReadIds.includes(dailyReadId);
      }

      return false;
    },
  },
});
